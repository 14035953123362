<template>
	<div class="out" :class="$i18n.locale == 'en'?'en-style':'' ">
		<div class="consumption-count">
			<div class="Recharge-consumption-btn">
				<div :class="[!history_flag?'unactive':'','Recharge-btn']" @click="btnTab(1)">
					{{$t('recharge.record')}}
				</div>
				<div :class="[history_flag?'unactive':'','consumption-btn']" @click="btnTab(2)">
					{{$t('main.expensesR')}}
				</div>
			</div>
		</div>
		<div class="Records-of-consumption cz-history" ref="cz_history" v-show="history_flag">
			<div class="no-data" v-if="cz_data_flag">
				<img src="../assets/img/no-data.png" alt="">
				<div class="no-data-tips">
					{{$t('main.nrr')}}
				</div>
			</div>
			<div class="Records-consumption-item" v-for="(item,index) in RechargeRecord" :key="index" @click="goDetail(item.id , 1)">
				<div class="Records-consumption-item-left">
					<div class="consumption-item-icon">
						<img src="../assets/img/history-icon.png" alt="">
					</div>
					<div class="">
						<div class="name-type">
							{{item.content | english(that)}}
						</div>
						<div class="cz-time">
							{{item.created_at}}
						</div>
					</div>
				</div>
				<div class="jf-count">
					+{{item.amount}} {{$t('main.points')}}
				</div>
			</div>
			
			
			<div class="bottom" v-show="is_cz_next && loading1"><van-loading size="24"/></div>
			
		</div>
		<div class="Records-of-consumption xf-history" ref="xf_history" v-show="!history_flag">
			<div class="no-data" v-if="xf_data_flag">
				<img src="../assets/img/no-data.png" alt="">
				<div class="no-data-tips">
					{{$t('main.npr')}}
				</div>
			</div>
			<div class="Records-consumption-item" v-for="(item,index) in RecordsOfConsumption" :key="index" @click="goDetail(item.id,2)">
				<div class="Records-consumption-item-left">
					<div class="consumption-item-icon">
						<img src="../assets/img/history-icon.png" alt="">
					</div>
					<div class="">
						<div class="name-type">
							{{item.content | english(that)}}
						</div>
						<div class="cz-time">
							{{item.created_at}}
						</div>
					</div>
				</div>
				<div class="jf-count">
					{{item.amount}} {{$t('main.points')}}
				</div>
			</div>
			<div class="bottom" v-show="is_xf_next && loading1"><van-loading size="24"/></div>
		</div>
		
	</div>
</template>

<script>
	export default {

		data() {
			return {
				consumptionInfo: {},
				history_flag: true,
				cz_data_flag: false,
				xf_data_flag: false,
				RechargeRecord:[],
				RecordsOfConsumption:[],
				is_cz_next:false,
				is_xf_next:false,
				cz_page:1,
				sf_page:1,
				loading1:false,
				that:this
			}
		},
		mounted() {
			this.getRechargeRecord()
			this.getRecordsOfConsumption()
			this.$refs.cz_history.addEventListener('scroll',this.throttle(this.scrollFn,500))
			this.$refs.xf_history.addEventListener('scroll',this.throttle(this.scrollFn,500))
		},
		filters:{
			english(content,that){
				if(that.$i18n.locale == 'en'){
					switch(content){
						case"平台赠送":return 'Platform Gifts';break;
						case"删除珍宝回退":return 'Delete Treasure Rollback';break;
						case"充值成功":return 'Recharged successfully';break;
						case"珍宝存证":return 'Ordinary deposit certificate';break;
						case"稀有珍宝存证":return 'Rare deposit certificate';break;
						case"恢复珍宝扣除":return 'Restore Treasure Deduction';break;
						case"稀有珍宝存证(补差价)":return 'Rare Treasure Deposit Certificate (Pay Difference)';break;
						default:return content;break;
					}
				}
				return content
			}
		},
		methods: {
			getRechargeRecord(id) {
				this.axios.post("/order/getTransactionList", {
						type: 1
					})
					.then((res) => {
						
						if (res.data.data.items.length==0) {
							this.cz_data_flag=true
						}else{
							this.RechargeRecord=res.data.data.items
							this.is_cz_next = res.data.data.hasMorePages
						}
					})
					.catch((err) => {
						
					});
			},
			getRecordsOfConsumption(id) {
				this.axios.post("/order/getTransactionList", {
						type: 2
					})
					.then((res) => {
						
						if (res.data.data.items.length==0) {
							this.xf_data_flag=true
						}else{
							this.RecordsOfConsumption=res.data.data.items
							this.is_xf_next = res.data.data.hasMorePages
						}
					})
					.catch((err) => {
						
					});
			},
			btnTab(val) {
				if (val == 1) {
					this.history_flag = true
				} else {
					this.history_flag = false
				}
			},
			goDetail(id,type) {
				this.$router.push(`/ConsumptionDetails?id=${id}&type=${type}`);
			},
			throttle(fn, time) {
				let canRun = true;
				return function() {
			
					if (!canRun)
						return;
					canRun = false;
					setTimeout(() => {
						fn.apply(this);
						canRun = true;
					}, time)
				}
			},
			scrollFn(e) {
				
				if (this.$refs.cz_history.scrollHeight - this.$refs.cz_history.scrollTop - this.$refs.cz_history.clientHeight <= 20) {
					
					if(this.history_flag){
						if (this.is_cz_next) {
							this.cz_page++
							this.loading1 = true
							this.axios.post("/order/getTransactionList",{page:this.cz_page,type:1}).then((res) => {
								
								
								this.RechargeRecord =  this.RechargeRecord.concat(res.data.data.items)
								
								this.is_cz_next = !!res.data.data.hasMorePages
								this.loading1 = false
								
							});
				
						}
					}else{
						if (this.is_xf_next) {

						
							this.cz_page++
							this.loading1 = true
							this.axios.post("/order/getTransactionList",{page:this.cz_page,type:2}).then((res) => {
								
								
								this.RecordsOfConsumption =  this.RecordsOfConsumption.concat(res.data.data.items)
								
								this.is_xf_next = !!res.data.data.hasMorePages
								this.loading1 = false
								
							});
				
						
						}
					}
					
				}
			
			},
		}
	}
</script>

<style scoped>
	.out {
		box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
		background-attachment: fixed;
		height: 100vh;
	}

	.consumption-count {
		background: url(../assets/img/jifen_bg.png) no-repeat center;
		background-size: cover;
		color: #f2df8d;
		color: blanchedalmond;
		font-size: 0.25rem;
		text-align: center;
		height: 1.35rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Recharge-consumption-btn {
		display: flex;
		font-size: 0.15rem;
		background-color: #FFFFFF;
		border-radius: 0.05rem;
		overflow: hidden;
		align-items: center;
	}

	.Recharge-consumption-btn div {
		width: 1.14rem;
		text-align: center;
		/* padding: 0.11rem 0; */
		line-height: normal;
		color: #a6210e;
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.15rem;
		border: solid 2px transparent;
	}
	.en-style .Recharge-consumption-btn div {
		padding: 0 0.1rem;
	}

	.Recharge-consumption-btn div.Recharge-btn {
		margin-right: -2px;
	}

	.Recharge-consumption-btn div.unactive {
		border: solid 2px #f2f2f2;
		color: #000000;
	}

	.Records-of-consumption {
		padding: 0 0.15rem;
		margin-top: 0.2rem;
		max-height: calc(100vh - 1.55rem);
		box-sizing: border-box;
		overflow: auto;
		padding-bottom: 0.15rem;
	}

	.Records-consumption-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.12rem 0.18rem;
		background-color: #FFFFFF;
		color: #000000;
		border-radius: 0.1rem;
		font-size: 0.12rem;
		margin-bottom: 0.1rem;
		line-height: normal;
	}

	.Records-consumption-item-left {
		display: flex;
		align-items: center;
	}

	.consumption-item-icon img {
		display: block;
		width: 0.2rem;
		margin-right: 0.1rem;
	}

	.Records-consumption-item-left .cz-time {
		color: #969696;
		margin-top: 0.05rem;
	}

	.jf-count {
		margin-right: 0.16rem;
		line-height: normal;
	}

	.cz-history .jf-count {
		color: #a6210e;
	}

	.no-data {
		background-color: #FFFFFF;
		border-radius: 0.05rem;
		text-align: center;
		margin: 0 0.12rem;
		overflow: hidden;
	}

	.no-data img {
		width: 1.63rem;
		margin-top: 0.63rem;
		margin-bottom: 0.4rem;
	}

	.no-data-tips {
		color: #848484;
		font-size: 0.16rem;
		text-align: center;
		margin-bottom: 0.61rem;
	}
	.bottom{
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
